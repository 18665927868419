import { LanguageString } from "../strings/string-helper.service";
import { TestStrings } from "./test/test.strings";
import { FwdStrings } from "./fwd/fwd.strings";
import { BocStrings } from "./boc/boc.strings";
import { HkaaStrings } from "./hkaa/hkaa.strings";
import { AvsecoStrings } from "./avseco/avseco.strings";
import { DseStrings } from "./dse/dse.strings";
import { FsdStrings } from "./fsd/fsd.strings";
import { TtxStrings } from "./ttx/ttx.strings";
import { IsaStrings } from "./isa/isa.strings";
import { SclStrings } from "./scl/scl.strings";
import { ArchsdStrings } from './archsd/archsd.strings';

export const TeamNoteClientStrings: {[domain: string]: LanguageString[]} = {
  "test": TestStrings,
  "fwd": FwdStrings,
  "boc": BocStrings,
  "hkaa": HkaaStrings,
  "avseco": AvsecoStrings,
  "avsecoProd": AvsecoStrings,
  "dse": DseStrings,
  "fsd": FsdStrings,
  "ttx": TtxStrings,
  "isa": IsaStrings,
  "scl": SclStrings,
  "archsd": ArchsdStrings
};
