/**
 * FSD Strings
 * 
 * Created by Shan - 2018-11-14
 */

import { LanguageConstant } from "../../constants/language.constant";
import { LanguageString } from "../../strings/string-helper.service";

export const TtxStrings: LanguageString[] = [
  {
    key: LanguageConstant.EN.key,
    strings: {
      GENERAL: {
        POWER_BY_MSG: "In case you forget the password, please contact ITMU"
      },
      WEBCLIENT: {
        MENU: {
          NEWS: "What's News",
          CHATS: "Messages",
          WEBDAV_MATERIAL: "File sharing"
        },
      },
      LOGIN: {
        GET_STATE: {
          USERNAME: {
            INPUT_TIP: "Departmental Portal ID",
            USERNAME: "DPID"
          }
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHT.key,
    strings: {
      GENERAL: {
        POWER_BY_MSG: "如忘記密碼，請與資訊科技管理組聯絡"
      },
      WEBCLIENT: {
        MENU: {
          NEWS: "最新消息",
          CHATS: "訊息",
          WEBDAV_MATERIAL: "檔案分享"
        }
      },
      LOGIN: {
        GET_STATE: {
          USERNAME: {
            INPUT_TIP: "部門入門網站登入名稱",
            USERNAME: "DPID"
          }
        },
        PASSWORD: {
          PASSWORD: "部門入門網站密碼"
        }
      }
    }
  },
  {
    key: LanguageConstant.ZH_CHS.key,
    strings: {
      GENERAL: {
        POWER_BY_MSG: "如忘记密码，请与资讯科技管理组联络"
      },
      WEBCLIENT: {
        MENU: {
          NEWS: "最新消息",
          CHATS: "讯息",
          WEBDAV_MATERIAL: "档案分享"
        }
      },
      LOGIN: {
        GET_STATE: {
          USERNAME: {
            INPUT_TIP: "部门入门网站登入名称",
            USERNAME: "DPID"
          }
        },
        PASSWORD: {
          PASSWORD: "部门入门网站密码"
        }
      }
    }
  }
];